import { useAuth0 } from "@auth0/auth0-react";
import { Box, Button, CircularProgress, Grid, InputLabel, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "../../../lib/appContext";
import PersonRolePicker from "../../people/PersonRolePicker";
import GoBack from "../../GoBack/GoBack";
import {
  containerStyle,
  inputLabelStyle,
  inputStyle,
  titleStyle
} from "../../styles/styles";
import TagPicker from "../../tags/TagPicker";
import TagStartDatePicker from "../../tags/TagStartDatePicker";
import "../HomeTabs/style.css";


export default function PersonEditor({ fetchTags, fetchPerson, fetchRoleTypes, savePerson }) {

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { id } = useParams(); // Get the person ID from the URL
  const { orgId } = useAppContext();

  const [isFetching, setIsFetching] = useState(false);
  const [formData, setFormData] = useState({
    organizationId: orgId,
    firstName: '',
    lastName: '',
    role: null,
    healthcareIdentifier: null,
    email: null,
    tagSerial: null,
    tagAssignmentStart: null
  });

  const usingEmail = ["Staff", "Visitor"].includes(formData.role);
  const usingNhsNumber = ["Patient"].includes(formData.role);

  // Fetch the person data if we are editing an existing person
  useEffect(() => {
    async function fetchData() {

      // If id is not present, we are adding a new person, so no need to fetch data
      if (!id) {
        return;
      }

      setIsFetching(true);
      console.log("Fetching data for person with ID: ", id);

      await fetchPerson(id, getAccessTokenSilently).then((person) => {
        setFormData({
          id: person.id,
          organizationId: orgId,
          firstName: person.firstName,
          lastName: person.lastName,
          role: person.role,
          healthcareIdentifier: person.healthcareIdentifier,
          email: person.email,
          tagSerial: person.tags.length > 0 ? person.tags[0].serial : null, // pick the first tag
          tagAssignmentStart: person.tags.length > 0 ? person.tags[0].assignmentStart : null // pick the first
        });
      });

      setIsFetching(false);

    }

    fetchData();
  }, [id, fetchPerson, getAccessTokenSilently]);


  // Handle any form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };


  // Handle the form submission and save the person
  const handleSubmit = (e) => {
    e.preventDefault();
    savePerson(formData, getAccessTokenSilently).then(() => {
      navigate('/people');
    });
  };


  return (
    <>
      <Box style={containerStyle}>
        <GoBack />

        <br></br>
        <Typography variant="h5" component="div" sx={titleStyle}>
          {id ? "Manage person" : "Add new person"}
        </Typography>

        {!isFetching ? (

          <form onSubmit={handleSubmit}>

            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="firstName" sx={inputLabelStyle}>
                  First name
                </InputLabel>

                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  style={inputStyle}
                  required
                />

              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="lastName" sx={inputLabelStyle}>
                  Last name
                </InputLabel>

                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  style={inputStyle}
                  required
                />

              </Grid>

              <Grid item xs={12} md={6}>
                <PersonRolePicker
                  fetchRoleTypes={fetchRoleTypes}
                  role={formData.role}
                  handleRoleChange={(role) => setFormData((prev) => ({ ...prev, role }))}
                />

              </Grid>

              {usingNhsNumber ? (
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="nhs" sx={inputLabelStyle}>
                    NHS Number
                  </InputLabel>

                  <input
                    type="text"
                    id="healthcareIdentifier"
                    name="healthcareIdentifier"
                    value={formData.healthcareIdentifier}
                    onChange={handleChange}
                    style={inputStyle}
                  />

                </Grid>
              ) : null}

              {usingEmail ? (
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="email" sx={inputLabelStyle}>
                    Email
                  </InputLabel>

                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    style={inputStyle}
                  />

                </Grid>
              ) : null}


              <Grid item xs={12} /> {/* Force new line to keep TagPicker and TagStartDatePicker together */}

              <Grid item xs={12} md={6}>
                <TagPicker
                  fetchTags={fetchTags}
                  tagSerial={formData.tagSerial}
                  handleChangeTagSerial={(tagSerial) => setFormData((prev) => ({ ...prev, tagSerial }))}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TagStartDatePicker
                  dateTimeStart={formData.tagAssignmentStart}
                  setDateTimeStart={(dateTimeStart) => setFormData((prev) => ({ ...prev, tagAssignmentStart: dateTimeStart }))}
                />
              </Grid>


              <Grid item xs={12}
                sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem', gap: '1rem' }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate("/people")}
                >
                  Cancel
                </Button>

                <Button type="Submit" variant="contained" color="primary">
                  {id ? "Save changes" : "Add person"}
                </Button>
              </Grid>

            </Grid>

          </form>
        ) : (
          <Grid container spacing={2} style={{ marginTop: "30px" }}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <CircularProgress />
            </Grid>
          </Grid>

        )}
      </Box>

    </>
  );
}
