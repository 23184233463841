import React, { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import AddIcon from "../../AddIcon/AddIcon";
import GoBack from "../../GoBack/GoBack";
import {
  addIconStyle,
  buttonIconStyle,
  buttonStyle,
  buttonTextStyle,
  containerStyle,
  cyanBg,
  rightCardStyle,
  titleStyle
} from "../../styles/styles";
import "../HomeTabs/style.css";


export default function AssetManagement({ tags, fetchAssets }) {

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [assetList, setAssetList] = React.useState();


  useEffect(() => {
    async function fetchData() {

      await fetchAssets(getAccessTokenSilently).then((assets) => {
        setAssetList(assets);
      });

    }

    fetchData();
  }, [fetchAssets, getAccessTokenSilently]);


  const handleAddNewAsset = () => {
    navigate("/assets/edit");
  };

  const handleEditAsset = (editedAsset) => {
    navigate(`/assets/edit/${editedAsset.id}`);
  };


  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'type', headerName: 'Type', flex: 1 },
    { field: 'serial', headerName: 'Serial', flex: 1 },
    {
      field: 'tags',
      headerName: 'Assigned Tags',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" >
          {params.row.tags.map((tag) => (
            <div
              style={{
                backgroundColor: "#E5F3FF",
                color: "#1763F7",
                borderRadius: "500px",
                padding: "5px 10px",
                display: "inline-block",
                margin: "5px",
              }}
            >
              {tag.serial}
            </div>
          ))}
        </Box>
      )
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0,
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              aria-label="Edit"
              style={{
                borderRadius: "500px",
                width: "36px",
                height: "36px",
              }}
              onClick={() => handleEditAsset(params.row.asset)}
            >
              <EditIcon
                style={{
                  color: "#1763F7",
                  fontSize: "14px",
                  height: "30px",
                }}
              />
            </IconButton>

          </div>
        </Box>
      )
    },
  ];

  const rows = assetList?.records ? assetList.records.map((asset, index) => (
    {
      id: index,
      name: asset.name,
      type: asset.type,
      serial: asset.serial,
      tags: asset.tags?.filter((tag) => tags.find((t) => t.serial === tag.serial)),
      asset: asset
    }
  )) : [];


  return (
    <div>
      <Box style={containerStyle}>
        <GoBack />
        <br></br>

        <Typography variant="h5" component="div" sx={titleStyle}>
          Asset Management
        </Typography>

        <Grid container spacing={2} style={{ marginTop: "30px" }}>
          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                display: "inline-flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                className="add-new-infection-button"
                style={{
                  ...buttonStyle,
                  ...cyanBg,
                  width: "auto",
                  justifyContent: "flex-end",
                  display: "inline-flex",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={handleAddNewAsset}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "top",
                    flexWrap: "wrap",
                  }}
                >
                  <AddIcon
                    style={{
                      ...buttonTextStyle,
                      ...buttonIconStyle,
                      ...addIconStyle,
                    }}
                  />
                  <Typography sx={buttonTextStyle}>Add new asset</Typography>
                </div>
              </button>
            </div>
          </Grid>
        </Grid>

        <Card sx={{ ...rightCardStyle, marginBottom: "40px" }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <DataGrid rows={rows} columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }} />
          </div>
        </Card>

      </Box>

    </div>
  );
}
