import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress, Grid } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { useCookies } from "react-cookie";
import "./App.css";
import Login from "./components/Login/Login";
import MainContainer from "./components/MainContainer/MainContainer";
import { useAppContext } from "./lib/appContext";


function App() {
  const { organization, setOrganization } = useAppContext();
  const [currentLocation, setCurrentLocation] = React.useState();
  const [sites, setSites] = React.useState();
  const [tags, setTags] = React.useState();
  const [accessToken, setAccessToken] = React.useState();
  const fetchingSites = React.useRef();
  const fetchingTags = React.useRef();
  const [cookies, setCookie] = useCookies(["defaultSite"]);
  const { isAuthenticated, logout, getAccessTokenSilently } = useAuth0();


  const handleSignout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const fetchTags = async () => {
    if (accessToken && !fetchingTags.current) {
      fetchingTags.current = true;

      const tagsData = await fetch(`${process.env.REACT_APP_API_URL}/tags`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const tagsJson = await tagsData?.json();

      setTags(
        tagsJson.map((tag) => ({
          wearableId: tag.electronicsId,
          serial: tag.serial,
          person: tag.assignedPerson?.fullName,
          asset: tag.assignedAsset?.name,
        }))
      );

      fetchingTags.current = false;
    }
  };

  const fetchSite = async (siteId) => {
    try {
      const siteData = await fetch(
        `${process.env.REACT_APP_API_URL}/sites/${siteId}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const siteDataJson = await siteData.json();
      setCurrentLocation(siteDataJson);
      console.log("siteDataJson");
      console.log(siteDataJson);
      setCookie("defaultSite", siteDataJson);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchSites = async () => {
    fetchingSites.current = true;

    const sitesData = await fetch(`${process.env.REACT_APP_API_URL}/sites`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const sitesList = await sitesData?.json();

    setSites(sitesList);

    // If the user has a default site set and it is in the list of sites, fetch that site
    if (cookies.defaultSite && sitesList.some((site) => site.id === cookies.defaultSite.id)) {
      fetchSite(cookies.defaultSite.id);
    } else {
      // Otherwise, fetch the first site in the list
      fetchSite(sitesList[0].id);
    }

  };

  const getOrgId = async () => {
    if (accessToken && !organization) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/organizations/my`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const organizationJson = await response.json();

        setOrganization(organizationJson);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const fetchToken = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
          scope: "openid profile email",
        },
      });

      setAccessToken(token);
    } catch (error) {
      console.error("Error getting access token:", error);
    }
  };

  // React.useEffect(() => {
  //   if (auth0User && !mergedUser && isAuthenticated) {
  //     getUser(auth0User);
  //   }
  // }, [isAuthenticated, auth0User]);

  React.useEffect(() => {
    getOrgId();
  }, [accessToken]);

  // React.useEffect(() => {
  //   if (mergedUser && isAuthenticated) {
  //     setLogoUrl(
  //       mergedUser.isCareHome
  //         ? `${process.env.REACT_APP_S3_URL}/proxximos_safer_care.png`
  //         : `${process.env.REACT_APP_S3_URL}/nhs_logo.png`
  //     );
  //     setMiddleNavbarImage(
  //       `${process.env.REACT_APP_S3_URL}/client_logos/${mergedUser.clientId}/logo.png` // Add cachebuster to force refresh
  //     );
  //   }
  // }, [mergedUser, isAuthenticated]);

  React.useEffect(() => {
    // Once the user has been fetched and merged with the user object from the RTLS API, fetch the list of sites
    // This runs when the token is set or whenever the mergedUser is set
    if (accessToken && !sites && !fetchingSites.current) {
      fetchSites();
    }
  }, [accessToken]);

  React.useEffect(() => {
    if (!tags) {
      fetchTags();
    }
  }, [accessToken]);

  React.useEffect(() => {
    fetchToken();
  }, [getAccessTokenSilently]);

  React.useEffect(() => {
    // Set the document title from the environment variable
    const siteTitle = process.env.REACT_APP_SITE_TITLE || 'Proxximos';
    document.title = siteTitle;
  }, []);

  React.useEffect(() => {
    fetchToken();
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Login />

        {isAuthenticated && organization && accessToken ? (
          <div id="frame" className={"wrapper"}>
            <MainContainer
              currentLocation={currentLocation}
              setCurrentLocation={setCurrentLocation}
              sites={sites}
              tags={tags}
              refreshTags={fetchTags}
              signOut={handleSignout}
              fetchSite={fetchSite}
            ></MainContainer>
          </div>
        ) : (
          <>
            <Grid container>
              <Grid item xs={12}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "100px",
                  }}
                >
                  <CircularProgress />
                </div>
              </Grid>
            </Grid>{" "}
          </>
        )}
      </LocalizationProvider>
    </>
  );
}

export default App;
