import { getAccessToken } from './helpers';

export const fetchRoleTypes = async (getAccessTokenSilently) => {
    const accessToken = await getAccessToken(getAccessTokenSilently);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/people/roles`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) throw new Error('Failed to fetch role types');
    const json = await response.json();

    return json.map((role) => ({
        token: role.token,
    }));

};
