import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid, InputLabel, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import GoBack from "../../GoBack/GoBack";
import {
  containerStyle,
  inputLabelStyle,
  inputStyle,
  titleStyle
} from "../../styles/styles";
import "../HomeTabs/style.css";
import { useAppContext } from "../../../lib/appContext";
import AssetTypePicker from "../../assets/AssetTypePicker";
import TagPicker from "../../tags/TagPicker";
import TagStartDatePicker from "../../tags/TagStartDatePicker";

export default function AssetEditor({ fetchTags, fetchAsset, fetchAssetTypes, saveAsset }) {

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { id } = useParams(); // Get the asset ID from the URL
  const { orgId } = useAppContext();

  const [isFetching, setIsFetching] = useState(false);
  const [formData, setFormData] = useState({
    organizationId: orgId,
    name: '',
    serial: '',
    assetTypeId: null,
    type: '',
    tagSerial: null,
    tagAssignmentStart: null
  });

  const selectedType = {
    id: formData.assetTypeId,
    label: formData.type
  };


  // Fetch the asset data if we are editing an existing asset
  useEffect(() => {
    async function fetchData() {

      // If id is not present, we are adding a new asset, so no need to fetch data
      if (!id) {
        return;
      }

      setIsFetching(true);
      console.log("Fetching data for asset with ID: ", id);

      await fetchAsset(id, getAccessTokenSilently).then((asset) => {
        setFormData({
          id: asset.id,
          organizationId: orgId,
          name: asset.name,
          serial: asset.serial,
          assetTypeId: asset.assetTypeId,
          type: asset.type,
          tagSerial: asset.tags.length > 0 ? asset.tags[0].serial : null, // pick the first tag
          tagAssignmentStart: asset.tags.length > 0 ? asset.tags[0].assignmentStart : null // pick the first
        });
      });

      setIsFetching(false);

    }

    fetchData();
  }, [id, fetchAsset, getAccessTokenSilently]);


  // Handle any form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleTypeChange = (value) => {
    setFormData((prev) => ({
      ...prev,
      type: value.label,
      assetTypeId: value.id
    }));
  };

  // Handle the form submission and save the asset
  const handleSubmit = (e) => {
    e.preventDefault();
    saveAsset(formData, getAccessTokenSilently).then(() => {
      navigate('/assets');
    });
  };


  return (
    <>
      <Box style={containerStyle}>
        <GoBack />

        <br></br>
        <Typography variant="h5" component="div" sx={titleStyle}>
          {id ? "Manage asset" : "Add new asset"}
        </Typography>

        {!isFetching ? (

          <form onSubmit={handleSubmit}>

            <Grid container spacing={2} style={{ marginTop: "30px" }}>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="name" sx={inputLabelStyle}>
                  Name
                </InputLabel>

                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  style={inputStyle}
                  required
                />

              </Grid>

              <Grid item xs={12} md={6}>
                <InputLabel htmlFor="serial" sx={inputLabelStyle}>
                  Serial Number
                </InputLabel>

                <input
                  type="text"
                  id="serial"
                  name="serial"
                  value={formData.serial}
                  onChange={handleChange}
                  style={inputStyle}
                />

              </Grid>

              <Grid item xs={12} md={6}>
                <AssetTypePicker
                  fetchAssetTypes={fetchAssetTypes}
                  type={selectedType}
                  handleTypeChange={handleTypeChange}
                />
              </Grid>

              <Grid item xs={12} /> {/* Force new line to keep TagPicker and TagStartDatePicker together */}

              <Grid item xs={12} md={6}>
                <TagPicker
                  fetchTags={fetchTags}
                  tagSerial={formData.tagSerial}
                  handleChangeTagSerial={(tagSerial) => setFormData((prev) => ({ ...prev, tagSerial }))}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TagStartDatePicker
                  dateTimeStart={formData.tagAssignmentStart}
                  setDateTimeStart={(dateTimeStart) => setFormData((prev) => ({ ...prev, tagAssignmentStart: dateTimeStart }))}
                />
              </Grid>


              <Grid item xs={12}
                sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem', gap: '1rem' }}
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate("/assets")}
                >
                  Cancel
                </Button>

                <Button type="Submit" variant="contained" color="primary">
                  {id ? "Save changes" : "Add asset"}
                </Button>
              </Grid>

            </Grid>

          </form>
        ) : (
          <Grid container spacing={2} style={{ marginTop: "30px" }}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <CircularProgress />
            </Grid>
          </Grid>

        )}
      </Box>

    </>
  );
}
