import { useAuth0 } from "@auth0/auth0-react";
import { TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import React, { useEffect, useState } from "react";
import {
  autocompleteInputStyle,
  inputLabelStyle
} from "../styles/styles";

const filter = createFilterOptions();

export default function AssetTypePicker({ fetchAssetTypes, type, handleTypeChange }) {

  const { getAccessTokenSilently } = useAuth0();
  const [assetTypes, setAssetTypes] = useState();

  // Fetch the asset types to populate the dropdown
  useEffect(() => {
    async function fetchData() {
      await fetchAssetTypes(getAccessTokenSilently).then((assetTypes) => {
        setAssetTypes(
          assetTypes.map((type) => ({
            id: type.id,
            label: type.name,
          }))
        );
      });
    }
    fetchData();
  }, [fetchAssetTypes, getAccessTokenSilently]);


  return (
    <>
      <label htmlFor="type" style={inputLabelStyle}>
        Type
      </label>

      <Autocomplete
        value={type}
        options={assetTypes}
        freeSolo
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="type"
        name="type"
        onChange={(e, newValue) => {
          if (typeof newValue === 'string') {
            handleTypeChange({
              label: newValue,
            });
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            handleTypeChange({
              label: newValue.inputValue,
            });
          } else {
            handleTypeChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some((option) => inputValue === option.label);
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              label: `Add "${inputValue}"`,
            });
          }

          return filtered;
        }}
        style={autocompleteInputStyle}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.label;
        }}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;
          return (
            <li key={key} {...optionProps}>
              {option.label}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField {...params} />
        )}
      />

    </>
  );
}
