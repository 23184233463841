import { getAccessToken } from './helpers';

export const fetchAsset = async (id, getAccessTokenSilently) => {
    const accessToken = await getAccessToken(getAccessTokenSilently);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/assets/${id}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) throw new Error('Failed to fetch asset');
    return await response.json();
};

export const fetchAssets = async (getAccessTokenSilently) => {
    const accessToken = await getAccessToken(getAccessTokenSilently);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/assets`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) throw new Error('Failed to fetch assets');
    return await response.json();   
};

export const saveAsset = async (asset, getAccessTokenSilently) => {
    const accessToken = await getAccessToken(getAccessTokenSilently);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/assets${asset.id ? `/${asset.id}` : ''}`, {
        method: asset.id ? 'PUT' : 'POST',
        headers: { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}` 
        },
        body: JSON.stringify(asset)
    });
    if (!response.ok) throw new Error('Failed to save asset');
    return await response.json();
};

