import { useAuth0 } from "@auth0/auth0-react";
import { InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  inputLabelStyle,
  inputStyle,
} from "../styles/styles";


export default function PersonRolePicker({ fetchRoleTypes, role, handleRoleChange }) {

  const { getAccessTokenSilently } = useAuth0();
  const [roleTypes, setRoleTypes] = useState();

  // Fetch the role  types to populate the dropdown
  useEffect(() => {
    async function fetchData() {
      await fetchRoleTypes(getAccessTokenSilently).then((roleTypes) => {
        setRoleTypes(
          roleTypes.map((role) => ({
            token: role.token,
          }))
        );
      });
    }
    fetchData();
  }, [fetchRoleTypes, getAccessTokenSilently]);


  return (
    <>
      <InputLabel htmlFor="role" sx={inputLabelStyle}>
        Role
      </InputLabel>

      <select
        id="role"
        name="role"
        value={role}
        onChange={(e) => handleRoleChange(e.target.value)}
        style={inputStyle}
      >
        <option value={null}> </option>
        {roleTypes ? (
          roleTypes.map((role, index) => (
            <option key={index} value={role.token}>
              {role.token}
            </option>
          ))
        ) : (
          <></>
        )}
      </select>
    </>
  );
}
