import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import React from "react";
import {
  datePickerStyle,
  inputLabelStyle,
  inputStyle
} from "../styles/styles";
import dayjs from "dayjs";


export default function TagStartDatePicker({ dateTimeStart, setDateTimeStart }) {

  const tagStartDateEditable = true;

  return (
    <>
      <label htmlFor="startdate" style={inputLabelStyle}>
        Worn since
      </label>
      <br />
      {tagStartDateEditable ? (
        <>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{
              okButtonLabel: "Save",
              todayButtonLabel: "Set date & time to now",
            }}
          >
            <DateTimePicker
              id="startdate"
              sx={{
                ...inputStyle,
                ...datePickerStyle,
                paddingTop: "0px",
                paddingBottom: "0px",
              }}
              format="DD-MM-YYYY h:mm A"
              slotProps={{
                actionBar: { actions: ["today", "accept"] },
              }}
              value={dayjs(dateTimeStart)}
              onChange={(newValue) => setDateTimeStart(dayjs(newValue))}
            />
          </LocalizationProvider>
        </>
      ) : (
        <input
          type="text"
          id="startdate"
          name="startdate"
          disabled
          value={
            (dateTimeStart ? dayjs(dateTimeStart) : dayjs(new Date()))
              .format("hh:mm on dddd, DD MMMM YYYY")
          }
          style={inputStyle}
        />
      )}
    </>
  );
}
