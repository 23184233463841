import { getAccessToken } from './helpers';

export const fetchAssetTypes = async (getAccessTokenSilently) => {
    const accessToken = await getAccessToken(getAccessTokenSilently);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/asset-types`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) throw new Error('Failed to fetch asset types');
    const json = await response.json();
    return json.records;
};
