import {
  Card,
  CardContent,
  Typography
} from "@mui/material";
import React from "react";
import { TwoLineDate } from "../../lib/dayjsConfig";
import CaseStatusSwitcher from "./CaseStatusSwitcher";


const leftCardStyle = {
  borderRadius: "8px",
  boxShadow: "none",
};

const caseNameStyle = {
  fontWeight: 600,
  fontSize: 12,
  color: "#333333",
  lineHeight: "2.35",
};

export default function CasePanel({
  infectedPerson,
}) {

  return (
    <>
      <Card style={leftCardStyle}>
        <CardContent>
          {infectedPerson ? (
            <>
              <div className="left-card-title">Name of case</div>
              <Typography variant="body2" sx={caseNameStyle}>
                {infectedPerson.infectedPerson
                  ? infectedPerson.infectedPerson.fullName
                  : `Unassigned tag`}
              </Typography>

              <div class="horizontal-line"></div>

              <div className="left-card-title">Role</div>
              <Typography variant="body2">
                {infectedPerson.infectedPerson
                  ? infectedPerson.infectedPerson.role
                  : ""}
              </Typography>

              <div class="horizontal-line"></div>

              <div className="left-card-title">Status</div>
              <CaseStatusSwitcher
                  initialStatus={infectedPerson.status}
                  caseId={infectedPerson.id}
                />

              <div class="horizontal-line"></div>

              <div className="left-card-title">Time and date of onset</div>
              <Typography variant="body2">
                <TwoLineDate date={infectedPerson.symptomsStart} />
              </Typography>

              <div class="horizontal-line"></div>

              <div className="left-card-title">Pathogen or disease</div>
              <div variant="body2" className="left-card-content">
                {infectedPerson.pathogen ? infectedPerson.pathogen : "Open"}
              </div>

              <div class="horizontal-line"></div>
              <div className="left-card-title">Infection site</div>
              <div variant="body2" className="left-card-content">
                {infectedPerson.infectionSite}
              </div>

              <div class="horizontal-line"></div>

              <div className="left-card-title"># contacts</div>
              <Typography variant="body2">
                {infectedPerson.contactPeople
                  ? infectedPerson.contactPeople.length
                  : 0}
              </Typography>
            </>
          ) : (
            <p>Case not found</p>
          )}
        </CardContent>
      </Card>
    </>
  );
}
