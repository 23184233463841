import React, { useEffect } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from "react-router-dom";
import AddIcon from "../../AddIcon/AddIcon";
import GoBack from "../../GoBack/GoBack";
import {
  addIconStyle,
  buttonIconStyle,
  buttonStyle,
  buttonTextStyle,
  containerStyle,
  cyanBg,
  rightCardStyle,
  titleStyle
} from "../../styles/styles";
import "../HomeTabs/style.css";


export default function PersonManagement({ tags, fetchPeople }) {

  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  const [peopleList, setPeopleList] = React.useState();


  useEffect(() => {
    async function fetchData() {

      await fetchPeople(getAccessTokenSilently).then((assets) => {
        setPeopleList(assets);
      });

    }

    fetchData();
  }, [fetchPeople, getAccessTokenSilently]);


  const handleAddNewPerson = () => {
    navigate("/people/edit");
  };

  const handleEditPerson = (editedPerson) => {
    navigate(`/people/edit/${editedPerson.id}`);
  };


  const columns = [
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'role', headerName: 'Role', flex: 0 },
    {
      field: 'details',
      headerName: 'Details',
      flex: 2,
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          {params.row.email && <div>{params.row.email}</div>}
          {params.row.healthcareIdentifier && <div>{`NHS : ${params.row.healthcareIdentifier}`}</div>}
          {params.row.dateOfBirth && <div>{`DoB: ${new Date(params.row.dateOfBirth).toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' })}`}</div>}
        </Box>
      )
    },
    {
      field: 'tags',
      headerName: 'Assigned Tags',
      flex: 1,
      renderCell: (params) => (
        <Box display="flex" >
          {params.row.tags.map((tag) => (
            <div
              style={{
                backgroundColor: "#E5F3FF",
                color: "#1763F7",
                borderRadius: "500px",
                padding: "5px 10px",
                display: "inline-block",
                margin: "5px",
              }}
            >
              {tag.serial}
            </div>
          ))}
        </Box>
      )
    },
    {
      field: 'edit',
      headerName: '',
      flex: 0,
      renderCell: (params) => (
        <Box display="flex" flexDirection="column">
          <div
            style={{
              width: "100%",
              display: "inline-flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              aria-label="Edit"
              style={{
                borderRadius: "500px",
                width: "36px",
                height: "36px",
              }}
              onClick={() => handleEditPerson(params.row.person)}
            >
              <EditIcon
                style={{
                  color: "#1763F7",
                  fontSize: "14px",
                  height: "30px",
                }}
              />
            </IconButton>

          </div>
        </Box>
      )
    },
  ];

  const rows = peopleList?.records ? peopleList.records.map((person, index) => (
    {
      id: index,
      name: person.fullName,
      role: person.role,
      email: person.email,
      healthcareIdentifier: person.healthcareIdentifier,
      dateOfBirth: person.dateOfBirth,
      tags: person.tags.filter((tag) => tags.find((t) => t.serial === tag.serial)),
      person: person
    }
  )) : [];


  return (
    <div>
      <Box style={containerStyle}>
        <GoBack />
        <br></br>

        <Typography variant="h5" component="div" sx={titleStyle}>
          Person Management
        </Typography>

        <Grid container spacing={2} style={{ marginTop: "30px" }}>
          <Grid item xs={12} md={12}>
            <div
              style={{
                width: "100%",
                display: "inline-flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                className="add-new-infection-button"
                style={{
                  ...buttonStyle,
                  ...cyanBg,
                  width: "auto",
                  justifyContent: "flex-end",
                  display: "inline-flex",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={handleAddNewPerson}
              >
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "top",
                    flexWrap: "wrap",
                  }}
                >
                  <AddIcon
                    style={{
                      ...buttonTextStyle,
                      ...buttonIconStyle,
                      ...addIconStyle,
                    }}
                  />
                  <Typography sx={buttonTextStyle}>Add new person</Typography>
                </div>
              </button>
            </div>
          </Grid>
        </Grid>

        <Card sx={{ ...rightCardStyle, marginBottom: "40px" }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <DataGrid rows={rows} columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }} />
          </div>
        </Card>

      </Box>

    </div>
  );
}
