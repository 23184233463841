import * as React from "react";
import CaseIconPng from "../../assets/icons/case-icon.png";

const CaseIcon = (props) => (
  <img
    src={CaseIconPng}
    alt="Case Icon"
    style={{ width: 35, height: 35 }}
    {...props}
  />
);
export default CaseIcon;
