import { useAuth0 } from "@auth0/auth0-react";
import { TextField } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from "react";
import {
  autocompleteInputStyle,
  inputLabelStyle
} from "../styles/styles";


export default function TagPicker({ fetchTags, tagSerial, handleChangeTagSerial }) {

  const { getAccessTokenSilently } = useAuth0();
  const [tags, setTags] = useState([]);

  const tagOptions = [
    { label: 'None', serial: null },
    ...tags?.map((tag) => ({
      label: `${tag.serial}${tag.person ? ` -- ${tag.person}` : ""}${tag.asset ? ` -- ${tag.asset}` : ""}`,
      serial: tag.serial,
    })),
  ];

  // Fetch the asset types to populate the dropdown
  useEffect(() => {
    async function fetchData() {
      await fetchTags(getAccessTokenSilently).then((tags) => {
        setTags(
          tags.map((tag) => ({
            wearableId: tag.electronicsId,
            serial: tag.serial,
            person: tag.assignedPerson?.fullName,
            asset: tag.assignedAsset?.name,
          }))
        );
      });
    }
    fetchData();
  }, [fetchTags, getAccessTokenSilently]);


  return (
    <>
      <label htmlFor="tag" style={inputLabelStyle}>
        Current Tag
      </label>

      <Autocomplete
        options={tagOptions}
        id="tag"
        getOptionLabel={(option) => option?.label || ""}
        value={tagOptions.find((option) => option.serial === tagSerial) || null}
        isOptionEqualToValue={(option, value) =>
          option?.serial === value?.serial
        }
        onChange={(event, selectedOption) => {
          handleChangeTagSerial(selectedOption?.serial);
        }}
        name="tag"
        style={autocompleteInputStyle}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  );
}
