import {
  Typography
} from "@mui/material";
import React from "react";
import {
  downloadLinkText
} from "../../styles/styles";
import "./style.css";

const consequencesTypeStyle = {
  padding: 0,
  mb: 1.1,
  color: "#333",
  fontWeight: 600,
  fontSize: 14,
  marginRight: "8px",
  marginTop: "6px",
  fontFamily: "Open Sans !important",
};

const leftButtonGroupStyle = {
  display: "inline-flex",
};


export default function ContactFilters({
  contacts, 
  minDuration,
  maxProximity,
  setVisibleData,
}) {
  

  const [proximityFilter, setProximityFilter] = React.useState(null);
  const [intensityFilter, setIntensityFilter] = React.useState(null);
  const [durationFilter, setDurationFilter] = React.useState(null);


  const proximities = [
    { value: 25, label: "25 cm" },
    { value: 50, label: "50 cm" },
    { value: 75, label: "75 cm" },
    { value: 100, label: "1 meter" },
    { value: 125, label: "1 meter 25 cm" },
    { value: 150, label: "1 meter 50 cm" },
    { value: 175, label: "1 meter 75 cm" },
    { value: 200, label: "2 meters" },
    { value: 250, label: "2 meters 50 cm" },
    { value: 300, label: "3 meters" },
    { value: 350, label: "3 meters 50 cm" },
    { value: 400, label: "4 meters" },
    { value: 450, label: "4 meters 50 cm" },
    { value: 500, label: "5 meters" },
    { value: 1000, label: "10 meters" },
    { value: 2000, label: "20 meters" },
    { value: 5000, label: "50 meters" },
  ];

  const durations = [
    { value: 1, label: "1 min" },
    { value: 2, label: "2 min" },
    { value: 3, label: "3 min" },
    { value: 4, label: "4 min" },
    { value: 5, label: "5 min" },
    { value: 10, label: "10 min" },
    { value: 15, label: "15 min" },
    { value: 20, label: "20 min" },
    { value: 25, label: "25 min" },
    { value: 30, label: "30 min" },
    { value: 35, label: "35 min" },
    { value: 45, label: "45 min" },
    { value: 75, label: "1 hour 15min" },
    { value: 90, label: "1 hour 30min" },
    { value: 105, label: "1 hour 45min" },
    { value: 120, label: "2 hours" },
    { value: 180, label: "3 hours" },
    { value: 240, label: "4 hours" },
    { value: 300, label: "5 hours" },
    { value: 600, label: "10 hours" },
  ];

  const intensities = [
    { value: 0, label: "0" },
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ];


  const handleProximityChange = (event) => {
    setProximityFilter(event.target.value);
    filterContacts(event.target.value, durationFilter, intensityFilter);
  };

  const handleDurationChange = (event) => {
    setDurationFilter(event.target.value);
    filterContacts(proximityFilter, event.target.value, intensityFilter);
  };

  const handleIntensityChange = (event) => {
    setIntensityFilter(event.target.value);
    filterContacts(proximityFilter, durationFilter, event.target.value);
  };


  const clearFilters = () => {
    setProximityFilter("Show all");
    setDurationFilter("Show all");
    setIntensityFilter("Show all");
    setVisibleData(
      contacts.map((contact) => ({
        ...contact,
        id: contact.id ? contact.id : contact.encounters[0].contactTagSerial,
      }))
    );
  };



  const filterContacts = (proximity, duration, intensity) => {
    debugger;
    const newContacts = contacts.map((contact) => {
      // contact.encounters is referring to actual contacts
      const newContactsArray = contact.encounters.filter((encounter) => {
        let include = true;

        if (proximity && proximity !== "Show all") {
          include = include && encounter.averageDistanceCm <= proximity;
        }

        if (duration && duration !== "Show all") {
          include = include && encounter.durationSeconds / 60 >= duration;
        }

        if (intensity && intensity !== "Show all") {
          include =
            include && parseInt(encounter.intensity) >= parseInt(intensity);
        }

        return include;
      });

      return {
        ...contact,
        encounters: newContactsArray,
      };
    });

    const filteredContacts = newContacts.filter((contact) => {
      return contact.encounters.length > 0;
    });

    setVisibleData(
      filteredContacts.map((contact) => ({
        ...contact,
        id: contact.id ? contact.id : contact.encounters[0].contactTagSerial,
      }))
    );
  };



  return (
    <>
      <div style={leftButtonGroupStyle}>
        {minDuration !== null && maxProximity !== null ? (
          <div className="pill">


            <div className="drop-menu-label" for="duration">
              Duration of contact
            </div>
            <select
              className="drop-menu"
              name="duration"
              id="duration"
              value={
                durationFilter ? durationFilter : minDuration
              }
              onChange={handleDurationChange}
            >
              <option value={"Show all"}>Show all</option>

              {durations.map((duration) => {
                return duration.value >= minDuration ? (
                  <option
                    value={duration.value}
                  >{`> ${duration.label}`}</option>
                ) : (
                  <></>
                );
              })}
            </select>


            <div className="drop-menu-label" for="proximity">
              Proximity
            </div>
            <select
              className="drop-menu"
              name="proximity"
              id="proximity"
              onChange={handleProximityChange}
              value={
                proximityFilter ? proximityFilter : maxProximity
              }
            >
              <option value={"Show all"}>Show all</option>

              {proximities.map((proximity) => {
                return proximity.value <= maxProximity ? (
                  <option value={proximity.value}>
                    {`< ${proximity.label}`}
                  </option>
                ) : (
                  <></>
                );
              })}
            </select>


            <div className="drop-menu-label" for="duration">
              Intensity
            </div>
            <select
              className="drop-menu"
              name="intensity"
              id="intensity"
              value={intensityFilter}
              onChange={handleIntensityChange}
            >
              <option value={"Show all"}>Show all</option>

              {intensities.map((intensity) => (
                <option value={intensity.value}>
                  {`> ${intensity.label}`}
                </option>
              ))}
            </select>


            <button
              style={{
                background: "none",
                border: "none",
                margin: 0,
                fontSize: 8,
              }}
              onClick={clearFilters}
            >
              <Typography
                id="type-menu-label"
                sx={{
                  ...consequencesTypeStyle,
                  ...downloadLinkText,
                  minWidth: 90,
                  cursor: "pointer",
                  color: "blue",
                  margin: 0,
                }}
              >
                Clear filters
              </Typography>
            </button>


          </div>
        ) : (
          <> </>
        )}
      </div>
    </>
  );
}
