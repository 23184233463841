import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import DownloadIcon from "@mui/icons-material/Download";
import {
  CardContent,
  Button,
  Select,
  FormControl,
  MenuItem,
  Grid,
  Stack,
  Card,
  CircularProgress,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import MapTab from "./MapTab";
import VideoControlUi from "./VideoControlUi";
import { convertMinutes, getAccessToken } from "../../../helpers/helpers";
import dayjs, {
  TwoLineDate,
  SingleLineDate,
} from "../../../../lib/dayjsConfig";
import {
  leftCardStyle,
  replayLeftCardStyle,
  replayRightCardStyle,
  titleStyle,
  cyanBg,
  navyBg,
  buttonStyle,
  zoomIconContainerStyle,
  contactSelectStyle,
} from "../../../styles/styles";
import PersonIcon from "../../../people/PersonIcon";
import ContactRiskSelect from "../../../ContactRiskSelect/ContactRiskSelect";
import EncounterRiskSelect from "../../../EncounterRiskSelect/EncounterRiskSelect";

const zoomPositionStyle = {
  position: "absolute",
  right: "0px",
  top: "200px",
  display: "block",
  zIndex: 5,
};

const caseNameStyle = {
  fontWeight: 600,
  fontSize: 12,
  color: "#333333",
};

const tableCaretDownIconStyle = {
  color: "#919191",
};

const mainContainerNavStyle = {
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginBottom: 20,
  flexDirection: "row-reverse",
};

const buttonTextStyle = {
  marginBottom: 1.5,
  color: "white",
  textDecoration: "underline",
  fontWeight: 600,
  fontSize: 12,
  fontFamily: "Open Sans",
};

const buttonGroupContainerStyle = {
  display: "inline-flex",
};

const downloadLinkText = {
  marginBottom: 1.5,
  color: "white",
  textDecoration: "underline",
  fontWeight: 600,
  display: "inline-block",
  fontSize: 12,
};

const downloadIconStyle = {
  color: "white",
  fontSize: 16,
  background: "transparent",
  margin: 0,
  padding: 0,
};

const downloadIconContainerStyle = {
  display: "inline-flex",
  background: "red",
  position: "relative",
  width: 25,
  height: 25,
  padding: 0,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#283555",
  marginTop: 2,
};

const downloadContainerStyle = {
  display: "inline-flex",
  alignItems: "center",
  position: "relative",
};

const modalStyle = {
  position: "absolute",
  top: "10%",
  left: "10%",
  width: "80%",
  height: "fit-content",
  paddingBottom: "20px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  background: "#FFFFFF 0% 0% no-repeat padding-box",
  borderRadius: "21px",
  border: "none",
};

export default function ViewMapReplayModal({
  infectedPerson,
  handleClose,
  open,
  user,
  replay,
  currentContact,
  tags,
  minDate,
  maxDate,
  nextContact,
  previousContact,
  handleGoToNextContact,
  handleGoToPreviousContact,
  currentLocation,
  renderPdf,
  currentEncounter,
  minDuration,
  maxProximity,
  handleChangeEncounter,
  handleRefresh,
}) {
  const [replaying, setReplaying] = React.useState(false);
  const timerRef = React.useRef();
  const [shouldKeepRefreshingData, setShouldKeepRefreshingData] =
    React.useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const replayStartTime = React.useRef(null);
  const [seekTime, setSeekTime] = React.useState(0);
  const actualStartTime = React.useRef();
  const actualDuration = React.useRef();
  const actualEndTime = React.useRef();
  const lastFrameSteps = React.useRef();
  const [timeMarkers, setTimeMarkers] = React.useState();
  const durationTimer = React.useRef();
  const [totalFrameSteps, setTotalFrameSteps] = React.useState(0);
  const [currentVideoSpeed, setCurrentVideoSpeed] = React.useState(250);
  const [fastForwardActive, setFastForwardActive] = React.useState(false);
  const [rewindActive, setRewindActive] = React.useState(false);
  const [showLoadingSpinner, setShowLoadingSpinner] = React.useState(true);
  const [contactReplayData, setContactReplayData] = React.useState();
  const [caseReplayData, setCaseReplayData] = React.useState();
  const [map, setMap] = React.useState();
  const modalSidebarRef = React.useRef();


  const mapWindow = React.useRef();

  const NORMAL_VIDEO_SPEED = 250;
  const FAST_VIDEO_SPEED = 50;
  const REWIND_VIDEO_SPEED = 50;

  const fetchMap = async () => {
    try {
      const at = await getAccessToken(getAccessTokenSilently);

      const siteData = await fetch(
        `${process.env.REACT_APP_API_URL}/sites/${currentLocation.id}`,
        {
          headers: {
            Authorization: `Bearer ${at}`,
          },
        }
      );
      const siteDataJson = await siteData.json();
      const mapUrl = siteDataJson.installations[0]?.areas[0]?.maps[0]?.imageUrl; // todo: handle multiple maps

      setMap(mapUrl);
    } catch (e) {
      console.log(e);
    }
  };

  const initializeReplayData = async () => {
    let frameStepsTotal = {};
    let combinedFrameSteps = 0;
    let timeMarkersArray = [];

    const minDate = currentContact.encounters[currentEncounter].start;
    const maxDate = currentContact.encounters[currentEncounter].end;
    const contactSerial =
      currentContact.encounters[currentEncounter].contactTagSerial;
    const caseSerial =
      currentContact.encounters[currentEncounter].sourceTagSerial;

    setShowLoadingSpinner(true);

    const at = await getAccessToken(getAccessTokenSilently);

    let contactResp = await fetch(
      `${process.env.REACT_APP_API_URL}/tags/${contactSerial}/positions?start=${minDate}&end=${maxDate}`,
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    let caseResp = await fetch(
      `${process.env.REACT_APP_API_URL}/tags/${caseSerial}/positions?start=${minDate}&end=${maxDate}`,
      {
        headers: {
          Authorization: `Bearer ${at}`,
        },
      }
    );

    let contactJsonData = await contactResp.json();
    let caseJsonData = await caseResp.json();

    let frame = 0;
    let frameDateTime;

    if (!frameStepsTotal) {
      frameStepsTotal = contactJsonData.length;
    }

    for (frame; frame <= contactJsonData.length - 1; frame++) {
      if (frame >= combinedFrameSteps) {
        combinedFrameSteps++;
      }

      frameDateTime = new Date(contactJsonData[frame].timestamp).getTime();

      timeMarkersArray.push({ value: frame, label: "" });

      if (
        frameDateTime < actualStartTime.current ||
        actualStartTime.current === undefined
      ) {
        actualStartTime.current = frameDateTime;
      }

      if (
        frameDateTime > actualEndTime.current ||
        actualEndTime.current === undefined
      ) {
        actualEndTime.current = frameDateTime;
      }
    }

    setTotalFrameSteps(combinedFrameSteps);

    setTimeMarkers(timeMarkersArray);

    actualDuration.current = actualEndTime.current - actualStartTime.current;

    setContactReplayData(contactJsonData);
    setCaseReplayData(caseJsonData);

    setShowLoadingSpinner(false);

    durationTimer.current = setTimeout(() => {
      setShouldKeepRefreshingData(false);
      setReplaying(false);
    }, actualDuration.current - 1);

    durationTimer.current = setTimeout(() => {
      setShouldKeepRefreshingData(false);
      setReplaying(false);
    }, currentContact.durationInMinutes * 60 * 1000);
  };

  const handlePause = () => {
    setReplaying(false);
    clearTimeout(timerRef.current);
  };

  const handlePlay = () => {
    setReplaying(true);
    setShouldKeepRefreshingData(true);

    if (replayStartTime.current === null) {
      replayStartTime.current = new Date().getTime();
    }
  };

  const handleSeek = (newFrameStep) => {
    if (newFrameStep !== seekTime) {
      // contactReplayData.forEach((index) => {
      lastFrameSteps.current = newFrameStep;
      // });

      setSeekTime(newFrameStep);
    }
  };

  React.useEffect(() => {
    getAccessToken(getAccessTokenSilently);
  }, []);

  const handleForward5Seconds = () => {
    if (fastForwardActive) {
      clearTimeout(timerRef.current);
      setFastForwardActive(false);
      setRewindActive(false);
      setCurrentVideoSpeed(NORMAL_VIDEO_SPEED);
    } else {
      clearTimeout(timerRef.current);
      setFastForwardActive(true);
      setRewindActive(false);
      setCurrentVideoSpeed(FAST_VIDEO_SPEED);
    }
  };

  const handleRewind5Seconds = () => {
    if (rewindActive) {
      clearTimeout(timerRef.current);
      setCurrentVideoSpeed(REWIND_VIDEO_SPEED);
      setRewindActive(false);
      setFastForwardActive(false);
    } else {
      clearTimeout(timerRef.current);
      setCurrentVideoSpeed(REWIND_VIDEO_SPEED);
      setRewindActive(true);
      setFastForwardActive(false);
    }
  };

  const fetchReplayData = async () => {
    if (rewindActive) {
      if (seekTime > 0) {
        // contactReplayData.forEach((index) => {
        const nextFrameStep = lastFrameSteps.current - 1;
        if (contactReplayData[nextFrameStep]) {
          lastFrameSteps.current = nextFrameStep;
        }
        // });
        setSeekTime(seekTime - 1);
      }
    } else {
      if (seekTime < totalFrameSteps) {
        // contactReplayData.forEach((index) => {
        const nextFrameStep = lastFrameSteps.current + 1;

        if (contactReplayData[nextFrameStep]) {
          lastFrameSteps.current = nextFrameStep;
        }
        // });
        setSeekTime(seekTime + 1);
      }
    }
  };

  const getContactProximity = () => {
    let smallestProximity = Infinity;

    currentContact.encounters.map((contact, key) => {
      if (parseInt(contact.averageDistanceCm) < smallestProximity) {
        smallestProximity = parseInt(contact.averageDistanceCm);
      }
      return "";
    });

    return `${smallestProximity} cm`;
  };

  const getContactDuration = () => {
    let sum = 0;

    if (currentContact.encounters.length === 0) {
      return <div>0 minutes</div>;
    }

    currentContact.encounters.map((contact, key) => {
      sum += parseInt(contact.durationSeconds);
      return "";
    });
    return convertMinutes(parseInt(sum) / 60);
  };

  const getContactIntensity = () => {
    return currentContact.intensity ? Math.round(currentContact.intensity) : 0;
  };

  React.useEffect(() => {
    if (currentContact && replay) {
      initializeReplayData();
    }
  }, [currentContact, currentEncounter, replay]);

  React.useEffect(() => {
    if (!map) {
      fetchMap();
    }
  }, []);

  React.useEffect(() => {
    if (
      caseReplayData &&
      contactReplayData &&
      shouldKeepRefreshingData &&
      replaying &&
      seekTime <= totalFrameSteps &&
      seekTime >= 0
    ) {
      timerRef.current = setTimeout(fetchReplayData, currentVideoSpeed);
    }
  }, [
    caseReplayData,
    contactReplayData,
    replaying,
    shouldKeepRefreshingData,
    seekTime,
    currentVideoSpeed,
    rewindActive,
    fastForwardActive,
  ]);

  function getCaseName(contact) {
    if (contact.infectedPerson) {
      return contact.infectedPerson.fullName;
    } else if (contact.contaminatedAsset) {
      return contact.contaminatedAsset.name;
    } else {
      return "Unknown";
    }
  }

  return (
    <div>
      {currentContact ? (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid container
            direction={"column"}
            wrap={"nowrap"}
            ref={mapWindow}
            sx={{
              ...modalStyle,
              width: "90%",
              height: "90vh",
              overflow: "hidden",
              top: "5%",
              left: "5%",
            }}
          >
            <Grid item>
              <Grid container
                justifyContent={"space-between"}
                sx={{
                  marginBottom: "20px",
                }}
              >

                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    ...titleStyle,
                    marginLeft: "20px",
                    marginBottom: "16px",
                    marginTop: "5px",
                  }}
                >
                  Replay Encounter
                </Typography>

                <Grid item>

                  {nextContact ? (
                    <button
                      className="see-next-contact-button"
                      onClick={handleGoToNextContact}
                      style={{ ...buttonStyle, ...cyanBg, marginRight: "23px" }}
                    >
                      <Typography sx={buttonTextStyle}>See Next Contact</Typography>
                    </button>
                  ) : previousContact ? (
                    <button
                      className="see-next-contact-button"
                      onClick={handleGoToPreviousContact}
                      style={{ ...buttonStyle, ...cyanBg, marginRight: "23px" }}
                    >
                      <Typography sx={buttonTextStyle}>
                        See Previous Contact
                      </Typography>
                    </button>
                  ) : (
                    <></>
                  )}

                  {/* <Button onClick={renderPdf}>
                    <div
                      style={{
                        display: "inline-flex",
                        ...buttonStyle,
                        ...navyBg,
                        marginRight: "15px",
                      }}
                      className="download-report-button"
                    >
                      <div
                        style={{
                          ...downloadContainerStyle,
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            ...downloadLinkText,
                            marginRight: "13px",
                            fontWeight: 500,
                          }}
                        >
                          Download report
                        </Typography>
                        <div
                          className="icon-container"
                          style={{
                            ...downloadIconContainerStyle,
                            marginTop: "-2px",
                          }}
                        >
                          <DownloadIcon
                            className="download-icon"
                            style={downloadIconStyle}
                          />
                        </div>
                      </div>
                    </div>
                  </Button> */}

                  <button
                    className="add-new-infection-button"
                    style={{
                      ...buttonStyle,
                      ...cyanBg,
                      justifyContent: "center",
                      display: "inline-flex",
                      textDecoration: "none",
                      alignItems: "center",
                    }}
                    onClick={handleClose}
                  >
                    Done
                  </button>

                </Grid>
              </Grid>
            </Grid>


            <Grid item xs style={{ flex: 1, height: "100%" }}>


              <Grid
                container
                spacing={2}
                style={{
                  height: "100%",
                }}
              >

                <Grid item 
                  xs={12} md={3} lg={2}
                  sx={{
                    height: "100%",
                  }}
                >
                  <Card
                    ref={modalSidebarRef}
                    sx={{
                      ...leftCardStyle,
                      ...replayLeftCardStyle,

                      height: "100%",
                    }}
                  >
                    <CardContent>
                      <div className="left-card-title">Name of Case</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="body2" sx={caseNameStyle}>
                          {getCaseName(infectedPerson)}
                        </Typography>
                        <PersonIcon
                          style={{
                            color: "white",
                            padding: "5px",
                            backgroundColor: "#E326DC",
                            borderRadius: "500px",
                            fontSize: "30px",
                          }}
                        ></PersonIcon>
                      </div>

                      <div class="horizontal-line"></div>

                      <div className="left-card-title">Case status</div>
                      <div variant="body2" className="left-card-content">
                        {infectedPerson.status}
                      </div>

                      <div class="horizontal-line"></div>

                      <div className="left-card-title">Infection site</div>
                      <div variant="body2" className="left-card-content">
                        {infectedPerson.infectionSite
                          ? infectedPerson.infectionSite
                          : "Unknown"}
                      </div>

                      <div class="horizontal-line"></div>

                      <div className="left-card-title">Pathogen</div>
                      <div variant="body2" className="left-card-content">
                        {infectedPerson.pathogen
                          ? infectedPerson.pathogen
                          : "Unknown"}
                      </div>

                      <div class="horizontal-line"></div>

                      <div className="left-card-title">Infection status</div>
                      <div variant="body2" className="left-card-content">
                        {infectedPerson.dateConfirmed
                          ? "Confirmed"
                          : "Unconfirmed"}
                      </div>

                      <div class="horizontal-line"></div>

                      <div className="left-card-title">Onset</div>
                      <div variant="body2" className="left-card-content">
                        <TwoLineDate date={infectedPerson.symptomsStart} />
                      </div>

                      <div class="horizontal-line"></div>

                      <div className="left-card-title">Transmission</div>
                      <div variant="body2" className="left-card-content">
                        {infectedPerson.transmissionType
                          ? infectedPerson.transmissionType
                          : "Unknown"}
                      </div>

                      <div class="horizontal-line"></div>

                      <div className="left-card-title">Contact policy</div>
                      <div variant="body2" className="left-card-content">
                        {infectedPerson.pathogen
                          ? `< ${maxProximity} cm
                       for > ${minDuration} min`
                          : "N/A"}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={9} lg={10}
                  sx={{
                    position: "relative",
                    height: "100%",
                  }}
                >

                  <Stack spacing={2} style={{ height: '100%' }}>


                    {/* Contact details */}
                    <Card
                      sx={{
                        ...leftCardStyle,
                        ...replayRightCardStyle,
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div>
                            <div className="left-card-title">Contact</div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ ...caseNameStyle, marginRight: "10px" }}
                              >
                                {`${currentContact.contactPerson
                                  ? currentContact.contactPerson.fullName
                                  : "Unknown"
                                  }`}
                              </Typography>
                              <PersonIcon
                                style={{
                                  color: "white",
                                  padding: "5px",
                                  backgroundColor: "#FF892D",
                                  borderRadius: "500px",
                                  fontSize: "30px",
                                }}
                              ></PersonIcon>
                            </div>
                          </div>
                          <div>
                            <div className="left-card-title">Contact risk</div>

                            <div className="left-card-content contact-risk">
                              <ContactRiskSelect
                                value={
                                  currentContact.risk
                                    ? currentContact.risk
                                    : "Open"
                                }
                                contact={currentContact}
                                handleRefresh={handleRefresh}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="left-card-title"># encounters</div>

                            <div className="left-card-content">
                              {currentContact.encounters
                                ? currentContact.encounters.length
                                : 0}
                            </div>
                          </div>
                          <div>
                            <div className="left-card-title">Total duration</div>

                            <div className="left-card-content">
                              {getContactDuration()}
                            </div>
                          </div>
                          <div>
                            <div className="left-card-title">
                              Median proximity
                            </div>

                            <div className="left-card-content">
                              {getContactProximity()}
                            </div>
                          </div>
                          <div>
                            <div className="left-card-title">Total intensity</div>

                            <div className="left-card-content">
                              {getContactIntensity()}
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Card>

                    <Card
                      sx={{
                        ...leftCardStyle,
                        ...replayRightCardStyle,
                        height: "100%",
                        flex: 1,
                      }}
                    >
                      <CardContent
                        sx={{ 
                          display: "flex",
                          flexDirection: "column",
                          height: "100%" 
                          }}
                          >

                        {/* Map display */}
                        <div
                          style={{
                            flex: 1,
                            width: "auto",
                            height: "100%",
                            overflow: "hidden",
                            backgroundColor: "#EFF4FF",
                            borderRadius: "8px",
                            // marginTop: "20px",
                            padding: "10px",
                          }}
                        >
                          {!showLoadingSpinner ? (
                            <>
                              {caseReplayData &&
                                caseReplayData.length > 0 &&
                                contactReplayData &&
                                contactReplayData.length > 0 ? (
                                <MapTab
                                  bgColor="#EFF4FF"
                                  controlsPosition="tr"
                                  currentMap={
                                    currentLocation?.installations[0].areas[0]
                                      .maps[0]
                                  }
                                  zoomIconContainerStyle={zoomIconContainerStyle}
                                  zoomPositionStyle={zoomPositionStyle}
                                  user={user}
                                  replay={replay}
                                  currentContact={currentContact}
                                  currentEncounter={currentEncounter}
                                  tags={tags}
                                  minDate={minDate}
                                  maxDate={maxDate}
                                  seekTime={seekTime}
                                  setReplaying={setReplaying}
                                  infectedPerson={infectedPerson}
                                  setShouldKeepRefreshingData={
                                    setShouldKeepRefreshingData
                                  }
                                  caseReplayData={caseReplayData}
                                  contactReplayData={contactReplayData}
                                  actualStartTime={actualStartTime}
                                  actualDuration={actualDuration}
                                  actualEndTime={actualEndTime}
                                  lastFrameSteps={lastFrameSteps}
                                  duration={totalFrameSteps}
                                  map={map}
                                  focusedPerson={
                                    currentContact.contactPersonSerial
                                  }
                                  width={
                                    mapWindow.current
                                      ? mapWindow.current.getBoundingClientRect()
                                        .width
                                      : null
                                  }
                                  height={"100%"}
                                ></MapTab>
                              ) : (
                                <div>Map data not available</div>
                              )}
                            </>
                          ) : (
                            <CircularProgress />
                          )}
                        </div>

                        {/* Video controls */}
                        {timeMarkers ? (
                          <VideoControlUi
                            handlePlay={handlePlay}
                            handlePause={handlePause}
                            handleForward5Seconds={handleForward5Seconds}
                            handleRewind5Seconds={handleRewind5Seconds}
                            duration={totalFrameSteps}
                            setCurrentTime={handleSeek}
                            currentTime={seekTime}
                            timeMarkers={timeMarkers}
                            fastForwardActive={fastForwardActive}
                            rewindActive={rewindActive}
                            playActive={replaying}
                          ></VideoControlUi>
                        ) : (
                          <></>
                        )}

                        {/* Encounter details */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <div style={{ maxWidth: 120 }}>
                            <div className="left-card-title">Date & time</div>

                            <div className="left-card-content">
                              <SingleLineDate
                                date={
                                  currentContact.encounters[currentEncounter]
                                    .start
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <div className="left-card-title">Duration</div>

                            <div className="left-card-content">
                              {convertMinutes(
                                currentContact.encounters[currentEncounter]
                                  .durationSeconds / 60
                              )}
                            </div>
                          </div>
                          <div>
                            <div className="left-card-title">
                              Median proximity
                            </div>

                            <div className="left-card-content">
                              {
                                currentContact.encounters[currentEncounter]
                                  .averageDistanceCm
                              }
                              cm
                            </div>
                          </div>

                          <div>
                            <div className="left-card-title">Intensity</div>

                            <div className="left-card-content">
                              {Math.round(
                                currentContact.encounters[currentEncounter]
                                  .intensity
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Encounter picker */}
                        {currentContact.encounters.length > 1 ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignContent: "center",
                              marginTop: "18px",
                            }}
                          >
                            <div
                              className="contact-events"
                              style={{
                                // display: "flex",
                                // justifyContent: "center",
                                // alignContent: "center",
                                // marginTop: "18px",
                                margin: "0px 30px",
                              }}
                            >
                              <div
                                className="left-card-title"
                                style={{ alignContent: "center" }}
                              >
                                Encounter
                              </div>
                              <Box
                                sx={{
                                  bgcolor: "white",
                                  // margin: "0px 30px",
                                  alignContent: "center",
                                }}
                              >
                                <FormControl
                                  id="contact-event-select"
                                  fullWidth
                                  style={contactSelectStyle}
                                >
                                  <Select
                                    value={currentEncounter}
                                    // label="Current contact event"
                                    onChange={handleChangeEncounter}
                                  >
                                    {currentContact.encounters.map(
                                      (encounter, index) => (
                                        <MenuItem value={index}>
                                          {`${dayjs(encounter.start).format(
                                            "LT ddd D MMM"
                                          )} 
                                          | ${convertMinutes(
                                            encounter.durationSeconds / 60
                                          )} 
                                          | ${encounter.averageDistanceCm / 100
                                            }m`}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </Box>
                            </div>
                            <div>
                              <div className="left-card-title">
                                Encounter risk
                              </div>
                              <div className="left-card-content contact-event-risk">
                                <EncounterRiskSelect
                                  value={
                                    currentContact.encounters[currentEncounter]
                                      .risk
                                  }
                                  encounter={
                                    currentContact.encounters[currentEncounter]
                                  }
                                  handleRefresh={handleRefresh}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}

                      </CardContent>
                    </Card>

                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}
