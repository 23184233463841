import { getAccessToken } from './helpers';

export const fetchPerson = async (id, getAccessTokenSilently) => {
    const accessToken = await getAccessToken(getAccessTokenSilently);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/people/${id}`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) throw new Error('Failed to fetch person');
    return await response.json();
};

export const fetchPeople = async (getAccessTokenSilently) => {
    const accessToken = await getAccessToken(getAccessTokenSilently);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/people`, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });

    if (!response.ok) throw new Error('Failed to fetch people');
    return await response.json();   
};

export const savePerson = async (person, getAccessTokenSilently) => {
    const accessToken = await getAccessToken(getAccessTokenSilently);
    const response = await fetch(`${process.env.REACT_APP_API_URL}/people${person.id ? `/${person.id}` : ''}`, {
        method: person.id ? 'PUT' : 'POST',
        headers: { 
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}` 
        },
        body: JSON.stringify(person)
    });
    if (!response.ok) throw new Error('Failed to save person');
    return await response.json();
};

